export default {
  keycloak: undefined,
  keycloakConfig: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: 'games',
    clientId: 'casino-backoffice',
  },
  keycloakOptions: {
    responseType: 'code',
    flow: 'standard',
    enablePkce: true,
    pkceMethod: 'S256',
    onLoad: 'login-required',
  },
};
