import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'UserProjects',
      component: () => import(/* webpackChunkName: "userProjects" */ '@/pages/UserProjects'),
    },
    {
      path: '/editor/preview/:id',
      name: 'EditorPreview',
      component: () => import(/* webpackChunkName: "editorPreview" */ '@/pages/EditorPreview'),
    },
    {
      path: '/editor/edit/:id',
      name: 'EditorEdit',
      component: () => import(/* webpackChunkName: "editorEdit" */ '@/pages/EditorEdit'),
    },
    {
      path: '/editor/:view/:id',
      name: 'EditorView',
      component: () => import(/* webpackChunkName: "editorView" */ '@/pages/EditorView'),
    },
  ],
});
