<template>
  <v-navigation-drawer app
                       clipped
                       color="panel"
                       :disable-resize-watcher="true"
                       :disable-route-watcher="true"
                       :expand-on-hover="false"
                       mini-variant
                       :mini-variant-width="100"
                       permanent
                       :stateless="true">
    <v-list v-if="hasProject">
      <v-list-item v-for="item in items"
                   :key="item.title"
                   link
                   :to="{ name: item.page, params: getItemParams(item) }">
        <v-list-item-icon>
          <v-icon color="grey"
                  large>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <LocalizedLabel :translation="item.title" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-list v-if="hasProject">
        <v-list-item link
                     :to="{ name: editItem.page, params: getItemParams(editItem) }">
          <v-list-item-icon>
            <v-icon color="grey"
                    large>
              {{ editItem.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <LocalizedLabel :translation="editItem.title" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'EditorMenu',
  computed: {
    ...mapGetters([
      'translations',
    ]),
    ...mapState([
      'editorProject',
      'gameViews',
    ]),
    items() {
      return this.gameViews;
    },
    hasProject() {
      return this.editorProject && this.editorProject.id;
    },
    editItem() {
      return {
        title: 'menuEditTitle',
        icon: 'mdi-cog',
        page: 'EditorEdit',
      };
    },
  },
  methods: {
    ...mapActions([
      'setEditorProject',
    ]),
    getItemParams(item) {
      return this.editorProject ? {
        id: this.editorProject.id,
        view: item.view,
      } : undefined;
    },
  },
};
</script>
