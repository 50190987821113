export default {
  CREATE_PROJECT: 'CREATE_PROJECT',
  SET_EDITOR_PROJECT: 'SET_EDITOR_PROJECT',
  SET_EDITOR_VIEW_MODE: 'SET_EDITOR_VIEW_MODE',
  SET_ERROR: 'SET_ERROR',
  SET_GAME_VIEW: 'SET_GAME_VIEW',
  SET_GAME_VIEW_SECTION: 'SET_GAME_VIEW_SECTION',
  SET_PROJECTS: 'SET_PROJECTS',
  UPDATE_EDITOR_PROJECT: 'UPDATE_EDITOR_PROJECT',
};
