export default {
  common: (locale) => {
    switch (locale) {
      case 'sr-Latn':
        return import(/* webpackChunkName: "i18n.common.sr-Latn" */'./common.sr-Latn');
      default:
        return import(/* webpackChunkName: "i18n.common" */'./common');
    }
  },
};
