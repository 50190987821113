import { defaultTo } from 'lodash';

export default {
  install: function install(Vue) {
    Vue.component('LocalizedLabel', {
      name: 'LocalizedLabel',
      functional: true,
      props: [
        'translation',
        'class',
      ],
      render(createElement, context) {
        const slots = context.slots();
        const key = slots.default ? slots.default[0].text.trim() : context.props.translation;
        const store = context.parent.$store;
        const translation = defaultTo(store.getters.translations[key], `[${key}]`);

        return createElement(
          'span',
          { staticClass: context.props.class },
          translation,
        );
      },
    });
  },
};
