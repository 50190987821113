import { extend, unset } from 'lodash';
import http from './http';
import stringUtils from '../utility/stringUtils';

const createProject = async (data) => {
  const response = await http.post('/projects', data);
  const result = response.data;

  return result;
};

const createStep = async (step, payload) => {
  const response = await http.post(`/builder/steps/${step}/create`, payload);
  const result = response.data;

  return result;
};

const getProject = async (id) => {
  const response = await http.get(`/projects/${id}`);
  const result = response.data;

  return result;
};

const getProjects = async (params) => {
  if (params) {
    if (!params.search) {
      unset(params, 'search');
    }
  }

  const response = await http.get('/projects', {
    params: extend({
      size: 10,
      page: 1,
    }, params),
  });
  const result = response.data;

  return result;
};

const getTemplate = async () => {
  const response = await http.get('/builder/steps/template');
  const result = response.data;

  return result;
};

const publishProject = async (id) => {
  const response = await http.post(`/projects/${id}/publish`);
  const result = response.data;

  return result;
};

const updateProject = async (id, data) => {
  const response = await http.patch(`/projects/${id}`, data);
  const result = response.data;

  return result;
};

const updateProjectAsset = async (id, file) => {
  const formData = new FormData();
  formData.append('image', file);
  const fileName = stringUtils.separateFileNameExt(file.name);
  const response = await http.put(`/projects/${id}/assets/${fileName}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const result = response.data;

  return result;
};

export default {
  createProject,
  createStep,
  getProject,
  getProjects,
  getTemplate,
  publishProject,
  updateProject,
  updateProjectAsset,
};
