import { assign, isNil } from 'lodash';

const sendBusMessage = (message) => {
  if (isNil(message)) {
    return false;
  }

  const iframe = document.getElementById('slotGame');

  if (isNil(iframe)) {
    return false;
  }

  iframe.contentWindow.postMessage(assign(message, {
    type: `SlotBuilder:${message.type}`,
  }), '*');

  return true;
};

const setBusListeners = () => {
  window.addEventListener('message', (event) => {
    if (event.data) {
      const customEvent = new CustomEvent(event.data.type, {
        detail: event.data,
      });

      window.dispatchEvent(customEvent);
    }
  });
};

export default {
  sendBusMessage,
  setBusListeners,
};
