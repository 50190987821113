<template>
  <div id="app">
    <TemplateDefault />
  </div>
</template>

<script>
import TemplateDefault from './layouts/TemplateDefault';

export default {
  name: 'App',
  components: {
    TemplateDefault,
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";
</style>
