<template>
  <v-app>
    <v-app-bar app
               clipped-left
               color="panel"
               flat>
      <v-img contain
             class="ml-6 mr-10"
             src="../assets/logo.png"
             height="24"
             max-width="24"
             aspect-ratio="1">
      </v-img>
      <v-toolbar-title class="text-subtitle-1">
        <router-link :to="{ name: projectsPage.page }"
                     class="tertiary--text text-decoration-none">
          <LocalizedLabel :translation="projectsPage.title" />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <EditorViewMenu v-if="isEditorPage" />
      <v-spacer />
      <EditorTools v-if="isEditorViewPage" />
    </v-app-bar>
    <EditorMenu v-if="isEditorViewPage" />
    <v-main>
      <router-view></router-view>
      <SystemError />
    </v-main>
  </v-app>
</template>

<script>
import EditorMenu from '../components/EditorMenu';
import EditorTools from '../components/EditorTools';
import EditorViewMenu from '../components/EditorViewMenu';
import SystemError from '../components/SystemError';

export default {
  name: 'TemplateDefault',
  components: {
    EditorMenu,
    EditorViewMenu,
    EditorTools,
    SystemError,
  },
  computed: {
    isProjectsPage() {
      return this.$route.name === this.projectsPage.page;
    },
    isEditorPage() {
      return ['EditorView', 'EditorPreview'].indexOf(this.$route.name) > -1;
    },
    isEditorViewPage() {
      return ['EditorView'].indexOf(this.$route.name) > -1;
    },
    projectsPage() {
      return { title: 'mainTitle', page: 'UserProjects' };
    },
  },
};
</script>
