<template>
  <v-btn-toggle v-model="viewMode"
                mandatory>
    <v-btn :value="item.name"
           :key="item.name"
           v-for="item in editorViewModes"
           icon>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EditorViewMenu',
  computed: {
    ...mapState([
      'editorViewMode',
      'editorViewModes',
    ]),
    viewMode: {
      get() {
        return this.editorViewMode;
      },
      set(value) {
        this.setEditorViewMode(value);
      },
    },
  },
  methods: {
    ...mapActions([
      'setEditorViewMode',
    ]),
  },
};
</script>
