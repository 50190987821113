import state from '../../store/state';

export default {
  request: [
    (config) => {
      const transformedConfig = config;
      const { keycloak } = state.auth;
      const token = keycloak ? keycloak.token : undefined;

      if (token) {
        transformedConfig.headers.authorization = `Bearer ${token}`;
      }

      return transformedConfig;
    },
    (error) => Promise.reject(error),
  ],
};
