import {
  isNil, cloneDeep, startCase,
} from 'lodash';
import types from './mutationTypes';
import { busApi, dataApi } from '../api';

export default {
  async createProject({ commit, dispatch }, data) {
    try {
      const projectData = await dataApi.createProject(data);

      dispatch('getProjects');

      return projectData;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });

      return undefined;
    }
  },
  async getProject({ commit }, id) {
    try {
      const result = await dataApi.getProject(id);

      if (isNil(result) || isNil(result.data)) {
        throw new Error('GetProjectError');
      }

      commit(types.SET_EDITOR_PROJECT, result.data);
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
    }
  },
  async getProjects({ commit }, params) {
    try {
      const result = await dataApi.getProjects(params);

      if (isNil(result) || isNil(result.data)) {
        throw new Error('GetProjectsError');
      }

      commit(types.SET_PROJECTS, result);
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });
    }
  },
  async publishProject({ commit, dispatch, state }) {
    try {
      const project = state.editorProject;
      const projectData = await dataApi.publishProject(project.id);

      dispatch('getProjects');

      return projectData;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: error.message,
        timeout: 4000,
      });

      return undefined;
    }
  },
  async updateProjectAsset({ commit, dispatch, state }, payload) {
    try {
      const project = state.editorProject;
      const projectData = await dataApi.updateProjectAsset(project.id, payload.file);
      dispatch('getProjects');

      return projectData;
    } catch (error) {
      commit(types.SET_ERROR, {
        message: `Could not upload file for ${startCase(payload.name)}`,
        timeout: 4000,
      });

      return undefined;
    }
  },
  async sendProjectUpdate({ state, commit }, updateBusOnly = false) {
    busApi.sendBusMessage({
      type: 'SetGamePreview',
      game: state.editorProject,
      gameView: state.gameView,
      gameViewSection: state.gameViewSection,
    });
    const projectId = state.editorProject.id;
    const data = cloneDeep(state.editorProject);

    if (!updateBusOnly) {
      [
        'id',
        'name',
        'createdAt',
        'updatedAt',
        'publishedAt',
        'published',
        'updated',
        'bonusType',
        'config',
        'configType',
        'gambleType',
        'gameType',
      ].forEach((key) => {
        delete data[key];
      });

      try {
        const projectData = await dataApi.updateProject(projectId, data);
        return projectData;
      } catch (error) {
        const errorDetails = error.response.data.details[0];
        commit(types.SET_ERROR, {
          message: errorDetails ? `Error updating project, ${errorDetails.message} for ${startCase(errorDetails.context.key)}` : 'Error updating project',
          timeout: 4000,
        });

        return undefined;
      }
    }
    return undefined;
  },
  setError({ commit }, value) {
    commit(types.SET_ERROR, value);
  },
  setEditorProject({ commit }, value) {
    commit(types.SET_EDITOR_PROJECT, value);
  },
  setEditorViewMode({ commit }, value) {
    commit(types.SET_EDITOR_VIEW_MODE, value);
  },
  setGameView({ commit, dispatch }, value) {
    commit(types.SET_GAME_VIEW, value);
    dispatch('sendProjectUpdate', true);
  },
  setGameViewSection({ commit, dispatch }, value) {
    commit(types.SET_GAME_VIEW_SECTION, value);
    dispatch('sendProjectUpdate', true);
  },
  updateEditorProject({ commit, dispatch }, value) {
    commit(types.UPDATE_EDITOR_PROJECT, value);
    dispatch('sendProjectUpdate');
  },
};
