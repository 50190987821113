<template>
  <v-snackbar :value="isVisible"
              :timeout="errorTimeout"
              color="error"
              @input="onChange">
    {{ errorMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white"
             text
             v-bind="attrs"
             @click="setError()">
        <LocalizedLabel translation="errorCloseLabel" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SystemError',
  computed: {
    ...mapState([
      'error',
    ]),
    errorMessage() {
      return this.error ? this.error.message : undefined;
    },
    errorTimeout() {
      return this.error ? this.error.timeout : undefined;
    },
    isVisible() {
      return this.errorMessage && this.errorMessage.length > 0;
    },
  },
  methods: {
    ...mapActions([
      'setError',
    ]),
    onChange(value) {
      if (value === false) {
        this.setError();
      }
    },
  },
};
</script>
