import Vue from 'vue';
import Keycloak from 'keycloak-js';
import { isNil } from 'lodash';
import VueCompositionAPI from '@vue/composition-api';
import LocalizedLabel from './components/LocalizedLabel';
import App from './App';
import router from './router';
import store from './store';
import { vuetify } from './plugins';
import i18n from './i18n';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(LocalizedLabel);

(async () => {
  const authState = store.state.auth;
  const keycloak = Keycloak(authState.keycloakConfig);
  const translations = (await i18n.common(store.state.locale)).default;
  store.state.translations = translations;

  keycloak.init(authState.keycloakOptions).then((auth) => {
    if (isNil(auth)) {
      window.location.reload();
    } else {
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');

      authState.keycloak = keycloak;
      store.dispatch('setKeycloakRefresh');
    }
  });
})();
