import { defaultTo } from 'lodash';

export default {
  getGameView: (state) => (view) => state.gameViews.find((n) => n.view === view),
  projects: (state) => defaultTo(state.projects, {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 1,
      pages: 1,
    },
  }),
  translations: (state) => state.translations,
  projectEditor: (state) => state.editorProject,
  imageSizes: (state) => state.allowedImageSizes,
};
