import types from './mutationTypes';

export default {
  async logout({ state, dispatch }) {
    await state.keycloak.logout();
    dispatch('setKeycloak');
  },
  setKeycloak({ commit }, keycloak) {
    commit(types.SET_KEYCLOAK, keycloak);
  },
  setKeycloakRefresh({ state, dispatch }) {
    const unixNow = new Date().getTime() / 1000;
    const { tokenParsed, timeSkew } = state.keycloak;
    const lifespan = Math.round(tokenParsed.exp + timeSkew - unixNow) * 1000;

    const keycloakRefresh = async () => {
      try {
        await state.keycloak.updateToken(lifespan);
        setTimeout(keycloakRefresh, lifespan);
      } catch (error) {
        dispatch('logout');
      }
    };

    setTimeout(keycloakRefresh, lifespan);
  },
};
