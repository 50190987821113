import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#ffe843',
        secondary: '#343434',
        tertiary: '#ffffff',
        accent: '#4d4d4d',
        error: '#ff5252',
        info: '#2196f3',
        success: '#4caf50',
        warning: '#ffc107',
        panel: '#1e1e1e',
      },
    },
  },
});
