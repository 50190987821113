<template>
  <div>
    <v-btn class="mr-4" :disabled="!isPreviewButtonEnabled">
      <LocalizedLabel translation="toolsPreviewLabel"/>
    </v-btn>
    <v-btn color="primary"
           class="grey--text text--darken-4"
           @click="publish"
           :disabled="isPublished"
           >
      <LocalizedLabel :translation="getPublishedStatusTranslation()" />
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EditorTools',
  computed: {
    ...mapState([
      'editorViewMode',
      'editorViewModes',
      'editorProject',
      'isPreviewButtonEnabled',
    ]),
    isPublished() {
      return this.editorProject && this.editorProject.published;
    },
  },
  methods: {
    ...mapActions([
      'publishProject',
      'setEditorViewMode',
    ]),
    publish() {
      if (this.editorProject) {
        this.publishProject();
      }
    },
    getPublishedStatusTranslation() {
      if (this.isPublished) {
        return 'toolsPublishedLabel';
      }
      return 'toolsPublishLabel';
    },
  },
};
</script>
