import { assign } from 'lodash';
import types from './mutationTypes';

export default {
  [types.SET_EDITOR_PROJECT](state, value) {
    state.editorProject = value;
  },
  [types.SET_EDITOR_VIEW_MODE](state, value) {
    state.editorViewMode = value;
  },
  [types.SET_ERROR](state, value) {
    state.error = value;
  },
  [types.SET_GAME_VIEW](state, value) {
    state.gameView = value;
  },
  [types.SET_GAME_VIEW_SECTION](state, value) {
    state.gameViewSection = value;
  },
  [types.SET_PROJECTS](state, value) {
    state.projects = value;
  },
  [types.UPDATE_EDITOR_PROJECT](state, value) {
    state.editorProject = assign(state.editorProject, value);
  },
};
