export default {
  editorProject: undefined,
  editorViewMode: 'desktop',
  editorViewModes: {
    desktop: {
      name: 'desktop',
      previewWidth: 1280,
      previewHeight: 720,
      icon: 'mdi-desktop-mac',
      messageBarWidth: 720,
      messageBarHeight: 72, // TODO: add for other panels
    },
    mobile: {
      name: 'mobile',
      previewWidth: 411,
      previewHeight: 823,
      icon: 'mdi-cellphone',
    },
    mobileLandscape: {
      name: 'mobileLandscape',
      previewWidth: 823,
      previewHeight: 411,
      icon: 'mdi-phone-rotate-landscape',
    },
  },
  allowedImageSizes: {
    backgroundImage: {
      height: 720,
      width: 1280,
    },
    slotBackgroundImage: {
      height: 464,
      width: 740,
    },
    reelBackgroundImage: {
      height: 464,
      width: 136,
    },
    onboardingBackgroundImage: {
      height: 720,
      width: 1280,
    },
    gambleBackgroundImage: {
      height: 1080,
      width: 1920,
    },
    slotLogoBackgroundImage: {
      height: 80,
      width: 240,
    },
    messageBarBackgroundImage: {
      height: 72,
      width: 720,
    },
    backgroundLeftAnimation: {
      height: 275,
      width: 564,
    },
    backgroundFullAnimation: {
      height: 275,
      width: 564,
    },
    backgroundRightAnimation: {
      height: 275,
      width: 564,
    },
    spinButtonBackgroundImage: {
      height: 12,
      width: 128,
    },
    wildSymbolWinImage: {
      height: 240,
      width: 240,
    },
    wildSymbolDefaultImage: {
      height: 144,
      width: 136,
    },
    bonusSymbolDefaultImage: {
      height: 144,
      width: 136,
    },
    bonusSymbolWinImage: {
      height: 144,
      width: 136,
    },
    symbol1DefaultImage: {
      height: 144,
      width: 136,
    },
    symbol1WinImage: {
      height: 144,
      width: 136,
    },
    symbol2DefaultImage: {
      height: 144,
      width: 136,
    },
    symbol2WinImage: {
      height: 144,
      width: 136,
    },
    bonusBackgroundImage: {
      height: 720,
      width: 1280,
    },
    bonusLogoImage: {
      height: 320,
      width: 320,
    },
    bonusPrizeOneDefaultImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeOneWinImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeTwoDefaultImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeTwoWinImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeThreeDefaultImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeThreeWinImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeFourDefaultImage: {
      height: 365,
      width: 294,
    },
    bonusPrizeFourWinImage: {
      height: 365,
      width: 294,
    },
  },
  error: undefined,
  gameView: undefined,
  gameViewSection: undefined,
  gameViews: [
    {
      title: 'menuGameScreensTitle',
      icon: 'mdi-google-controller',
      page: 'EditorView',
      view: 'game',
    },
    {
      title: 'menuGambleGameTitle',
      icon: 'mdi-cards',
      page: 'EditorView',
      view: 'gamble',
    },
    {
      title: 'menuBonusGameTitle',
      icon: 'mdi-gift',
      page: 'EditorView',
      view: 'bonus',
    },
    {
      title: 'menuGameSettingsTitle',
      icon: 'mdi-tune',
      page: 'EditorView',
      view: 'settings',
    },
    {
      title: 'menuOnboardingScreenTitle',
      icon: 'mdi-dock-window',
      page: 'EditorView',
      view: 'onboarding',
    },
  ],
  locale: 'en',
  projects: undefined,
  translations: undefined,
  isPreviewButtonEnabled: false,
};
